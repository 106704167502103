import { Injectable } from "@angular/core";

import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";

import { AdvisorService } from "./advisor.service";
import { ToggleService } from "./toggle.service";

import { RepricingRemoteService } from "../remote/repricing-remote.service";

import { AdvisorChannelEnum } from "../../core/enums/advisor-channel.enum";
import { AdvisorDecisionTypeEnum } from "../../core/enums/advisor-decision-type.enum";
import { ToggleEnum } from "../../core/enums/toggle.enum";
import { SalesProcessTypeEnum } from "../../core/enums/sales-process-type.enum";

import { SearchRepricingResponse } from "../../core/interfaces/search-repricing-response.interface";

export type account = { accountNumber: string; accountType: string };
@Injectable({
  providedIn: "root",
})
export class RepricingCampaignService {
  private searchResponse = { repricing: [] } as SearchRepricingResponse;
  private repricingV2ShortFlow: boolean = true;

  constructor(
    private advisorService: AdvisorService,
    private repricingRemoteService: RepricingRemoteService,
    private toggleService: ToggleService,
  ) {}

  get repricingData(): SearchRepricingResponse {
    return this.searchResponse;
  }

  isEnable(): boolean {
    return (
      this.toggleService.getToggleEnabledById(
        ToggleEnum.SHORT_FLOW_CALL_CENTER_REPRICING_TOGGLE,
      ) &&
      this.advisorService.getAdvisor().idChannel ===
        AdvisorChannelEnum.CALL_CENTER
    );
  }

  isEnableV2(): boolean {
    return this.repricingV2ShortFlow && this.isEnableV2Channel();
  }

  isEnableV2Channel(): boolean {
    const idChannel = this.advisorService.getAdvisor().idChannel;
    const repricingV2Toggle = this.toggleService.getToggleEnabledById(
      ToggleEnum.SHORT_FLOW_REPRICING_V2,
    );

    return (
      repricingV2Toggle &&
      (idChannel === AdvisorChannelEnum.OFFICE_NETWORK ||
        idChannel === AdvisorChannelEnum.LOYALTY)
    );
  }

  search(
    documentType: string,
    documentNumber: string,
    salesModelRepricing: boolean,
  ): Observable<SearchRepricingResponse> {
    return this.repricingRemoteService
      .repricingSearch({ documentType, documentNumber, salesModelRepricing })
      .pipe(
        map((response: SearchRepricingResponse) => {
          this.searchResponse = response;
          if (
            response.salesModel === SalesProcessTypeEnum.REPRICING &&
            salesModelRepricing
          ) {
            this.advisorService.setAdvisor({
              ...this.advisorService.getAdvisor(),
              decisionType: AdvisorDecisionTypeEnum.PAYROLL_LOAN_REPRICING,
            });
          }
          return response;
        }),
        catchError((error) => {
          return throwError({ ...error, code: error.businessErrorCode });
        }),
      );
  }

  getAccount(payerName: string): account | null {
    if (this.isEnable() || this.isEnableV2()) {
      const find = this.repricingData.repricing.find(
        (item) => item.payer === payerName,
      );
      if (find) {
        return { accountNumber: find.account, accountType: find.accountType };
      }
    }
    return null;
  }

  setRepricingV2ShortFlow(value: boolean) {
    this.repricingV2ShortFlow = value;
  }
}
