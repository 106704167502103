import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { FederatedAuthService } from "../../../services/local/federated-auth.service";
import { RedirectService } from "../../../services/local/redirect.service";
import { FrontTransversalService } from "../../../services/local/front-transversal.service";

@Component({
  selector: "app-login-advisor",
  template: "<app-two-column></app-two-column>",
})
export class LoginAdvisorComponent implements OnInit {
  data: any;

  constructor(
    private federatedAuthService: FederatedAuthService,
    private location: Location,
    private redirectService: RedirectService,
    private route: ActivatedRoute,
    private router: Router,
    private frontTransversalService: FrontTransversalService,
  ) {}

  async ngOnInit() {
    await this.router.navigate(
      [
        "twocolumn",
        {
          outlets: {
            "part-left": ["formloginadvisor"],
            "part-right": ["mainimage"],
          },
        },
      ],
      { skipLocationChange: true },
    );

    this.frontTransversalService.signIn().catch(() => {
      this.validateFederatedAuth();
    });
  }

  private validateFederatedAuth() {
    if (this.federatedAuthService.federatedAuthEnabled()) {
      this.federatedAuth();
    }
  }

  private federatedAuth() {
    this.data = this.route.snapshot.data;
    if (!this.data || !this.data.access) {
      this.redirectService.redirectToFederatedAuth();
    } else {
      this.frontTransversalService.removeFlag();
      this.location.replaceState(window.location.pathname);
      if (
        this.data.access &&
        this.data.access.error &&
        this.data.access.error !== null
      ) {
        this.router.navigate(["overflowerror"]);
      }
    }
  }
}
