import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { environment } from "../../../environments/environment";

import { AdvisorService } from "../local/advisor.service";
import { CognitoService } from "../local/cognito.service";

@Injectable({
  providedIn: "root",
})
export class RepricingRemoteService {
  constructor(
    private advisorService: AdvisorService,
    private http: HttpClient,
    private cognitoService: CognitoService,
  ) {}

  repricingSearch(body: any): Observable<any> {
    let token = this.cognitoService.getTokenNew();
    if (!token) {
      const advisorInfo = this.advisorService.getAdvisor();
      token = advisorInfo.cognitoToken;
    }

    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: token,
    });
    return this.http.post(
      environment.serverUrlSalaryLoans + "customer/campaign/repricing/search",
      body,
      { headers },
    );
  }
}
