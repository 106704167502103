import { Injectable } from "@angular/core";
import { catchError, map, mergeMap, retry, switchMap } from "rxjs/operators";
import { Observable, of, throwError, timer } from "rxjs";
import { Resolve } from "@angular/router";

import { environment } from "../../../environments/environment";

import { ApplicationConstantsEnum } from "../../core/enums/applications-constants.enum";
import { OverflowErrorsEnum } from "../../core/enums/overflow-errors.enum";
import { ToggleEnum } from "../../core/enums/toggle.enum";

import { FederatedAuthRemoteService } from "../remote/federated-auth-remote.service";

import { AdvisorService } from "./advisor.service";
import { OverflowErrorsService } from "./overflow-errors.service";
import { ToggleService } from "./toggle.service";
import { FrontTransversalService } from "./front-transversal.service";

@Injectable({
  providedIn: "root",
})
export class FederatedAuthService implements Resolve<any> {
  private clientId: string = "";

  constructor(
    private advisorService: AdvisorService,
    private overflowErrorsService: OverflowErrorsService,
    private federatedAuthRemoteService: FederatedAuthRemoteService,
    private frontTransversalService: FrontTransversalService,
    private toggleService: ToggleService,
  ) {}

  async resolve(): Promise<any | null> {
    if (this.federatedAuthEnabled()) {
      try {
        await this.setClientId();
      } catch (error) {
        return error;
      }

      const urlParams: URLSearchParams = new URLSearchParams(
        window.location.search,
      );
      const code: string = urlParams.get("code");

      if (!code) {
        return null;
      }

      return this.getTokenDetailsFromCognito(code).toPromise();
    } else {
      let advisor = this.advisorService.getAdvisor();
      if (!advisor) {
        advisor = {};
      }

      advisor.federatedAuthClientId = ApplicationConstantsEnum.CHARACTER_EMPTY as string;
      advisor.federatedAuthToken = ApplicationConstantsEnum.FEDERATED_AUTH_DUMMY_TOKEN as string;

      this.advisorService.setAdvisor(advisor);
    }
  }

  federatedAuthEnabled(): boolean {
    return (
      environment.federatedAuthEnabled &&
      this.toggleService.getToggleEnabledById(
        ToggleEnum.FEDERATED_AUTHENTICATION,
      )
    );
  }

  getTokenDetailsFromCognito(code: string): Observable<any | null> {
    return this.federatedAuthRemoteService
      .getTokenDetailsFromCognito(code, this.clientId)
      .pipe(
        switchMap((response: any) => {
          this.advisorService.setAdvisor({
            federatedAuthClientId: this.clientId,
            federatedAuthToken: response.access_token,
          });

          return of(response);
        }),
        catchError((error) => {
          this.advisorService.clearAdvisor();
          if (this.frontTransversalService.isToggleEnabled()) {
            this.frontTransversalService.redirectToFrontTransversal();
            return of(error);
          }
          this.setFederatedAuthError(error);

          return of(error);
        }),
      );
  }

  getClientId() {
    return this.clientId;
  }

  private setClientId() {
    return this.federatedAuthRemoteService
      .getFrontendParams()
      .pipe(
        map(
          (dataFrontendParams) => (this.clientId = dataFrontendParams.clientId),
        ),
        catchError((errorFrontendParams) => {
          this.setFederatedAuthError(errorFrontendParams);
          return timer(1000).pipe(
            mergeMap(() => throwError(errorFrontendParams)),
          );
        }),
      )
      .pipe(retry(ApplicationConstantsEnum.NUMBER_ATTEMPTS))
      .toPromise();
  }

  private setFederatedAuthError(error: any) {
    this.overflowErrorsService.setOverflowError(
      error &&
        error.error &&
        error.error.error &&
        error.error.error ===
          ApplicationConstantsEnum.FEDERATED_AUTH_INVALID_GRANT
        ? OverflowErrorsEnum.FEDERATED_AUTH_INVALID_GRANT_ERROR
        : OverflowErrorsEnum.TECHNICAL_ERROR,
    );
  }
}
