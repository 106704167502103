import { Injectable } from "@angular/core";
import { of, throwError } from "rxjs";
import { catchError, mergeMap } from "rxjs/operators";

import { ApplicationConstantsEnum } from "../../core/enums/applications-constants.enum";
import { MessageEnum } from "../../core/enums/message.enum";

import { IRequestPhoneAuth } from "../../core/interfaces/request-phone-auth-rnec.interface";
import { IRequestValidateRnec } from "../../core/interfaces/request-validate-rnec.interface";

import { PhoneAuthRemoteService } from "../remote/phone-auth-remote.service";
import { RnecRemoteService } from "../remote/rnec-remote.service";

import { AdvisorService } from "./advisor.service";
import { CognitoService } from "./cognito.service";

@Injectable({
  providedIn: "root",
})
export class RnecService {
  constructor(
    private advisorService: AdvisorService,
    private rnecRemoteService: RnecRemoteService,
    private cognitoService: CognitoService,
    private phoneAuthService: PhoneAuthRemoteService,
  ) {}

  postValidateRnec(requestValidateRnec: IRequestValidateRnec) {
    return this.rnecRemoteService.postValidateRnec(requestValidateRnec).pipe(
      mergeMap((responseValidateRnec) => {
        if (responseValidateRnec.code === MessageEnum.SecurityManager0021) {
          return of(MessageEnum.SecurityManager0021_Rnec);
        }

        return this.postUpdatePhoneAuth(
          {
            authStatus:
              responseValidateRnec.response ??
              ApplicationConstantsEnum.CHARACTER_EMPTY,
            authType: ApplicationConstantsEnum.CERTIHUELLA,
            code: requestValidateRnec.nut,
            clientIdNumber: requestValidateRnec.documentNumber,
            clientIdType: requestValidateRnec.documentType,
            dateTime: responseValidateRnec.date,
          },
          responseValidateRnec.response
            ? MessageEnum.RNEC_CASE_AUTHORIZED
            : responseValidateRnec.code,
        );
      }),
      catchError((errorValidateRnec) => {
        // eslint-disable-next-line no-console
        console.log(
          `RnecService - postValidateRnec - Error ${JSON.stringify(
            errorValidateRnec,
          )}`,
        );

        return throwError(
          errorValidateRnec.code ?? MessageEnum.ERROR_TECHNICAL,
        );
      }),
    );
  }

  private postUpdatePhoneAuth(
    requestPhoneAuth: IRequestPhoneAuth,
    messageEnum: MessageEnum,
  ) {
    let token = this.cognitoService.getTokenNew();
    if (!token) {
      const advisorInfo = this.advisorService.getAdvisor();
      token = advisorInfo.cognitoToken;
    }
    return this.phoneAuthService
      .postUpdatePhoneAuth(requestPhoneAuth, token)
      .pipe(
        mergeMap(() => of(messageEnum)),
        catchError((errorUpdatePhoneAuth) => {
          // eslint-disable-next-line no-console
          console.log(
            `RnecService - postUpdatePhoneAuth - Error ${JSON.stringify(
              errorUpdatePhoneAuth,
            )}`,
          );

          return throwError(errorUpdatePhoneAuth);
        }),
      );
  }
}
