import { Injectable } from "@angular/core";
import { ToggleService } from "./toggle.service";

import { ToggleEnum } from "../../core/enums/toggle.enum";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class FrontTransversalService {
  constructor(private toggleService: ToggleService) {}

  signIn() {
    if (!this.isToggleEnabled()) return Promise.reject();

    const token = this.getParamsFromURL("data");
    this.saveFlag(token);

    if (!this.getFlag()) {
      this.redirectToFrontTransversal();
      return Promise.resolve();
    }
    return Promise.reject();
  }

  isToggleEnabled(): boolean {
    return this.toggleService.getToggleEnabledById(
      ToggleEnum.FRONT_TRANSVERSAL_ASISTIDO,
    );
  }

  redirectToFrontTransversal(): void {
    window.location.href = environment.frontTransversalUlr;
  }

  removeFlag(): void {
    localStorage.removeItem("data");
  }

  private getParamsFromURL(paramKey: string): string {
    const urlParams: URLSearchParams = new URLSearchParams(
      window.location.search,
    );
    return urlParams.get(paramKey);
  }

  private getFlag() {
    return localStorage.getItem("data");
  }

  private saveFlag(data: any) {
    if (data) localStorage.setItem("data", "true");
  }
}
