import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { IListSelect } from "@avaldigitallabs/bpop-visual-components-frontend-lib";

import { IAdvisor } from "../../../core/interfaces/advisor.interface";
import { ICognitoCredentials } from "../../../core/interfaces/cognito-credentials.interface";

import { AdvisorTypeEnum } from "../../../core/enums/advisor-type.enum";
import { ApplicationConstantsEnum } from "../../../core/enums/applications-constants.enum";
import { GoogleAnalyticsEventsEnum } from "../../../core/enums/google-analytics-events.enum";
import { MessageEnum } from "../../../core/enums/message.enum";

import { AdvisorService } from "../../../services/local/advisor.service";
import { CognitoService } from "../../../services/local/cognito.service";
import { GoogleAnalyticsEventsService } from "../../../services/local/google-analytics-events.service";
import { GoogleRecaptchaService } from "../../../services/local/google-recaptcha.service";
import { OrganizationManagerService } from "../../../services/local/organization-manager.service";
import { ToggleService } from "../../../services/local/toggle.service";
import { TrainingService } from "../../../services/local/training.service";

import { TealiumUtagService } from "../../../tealium/utag.service";

import { FormPopupMessageBoxComponent } from "../form-popup-message-box/form-popup-message-box.component";

@Component({
  selector: "app-form-login-advisor",
  templateUrl: "./form-login-advisor.component.html",
})
export class FormLoginAdvisorComponent implements OnInit, AfterViewInit {
  @ViewChild(FormPopupMessageBoxComponent, { static: false })
  formPopupMessageBoxComponent: FormPopupMessageBoxComponent;

  cognitoToken: string;
  description: string;
  leaveFocusTextAdvisor: boolean;
  loadingDocumentAdvisor = true;
  loadingButtonAdvisor: boolean;
  receivedChildMessage: string;
  retryLookToggleService = 0;
  showSearchAdvisorSuccess: boolean;
  showAdministrativeOffice: boolean;

  advisor: IAdvisor;
  formGroup: UntypedFormGroup;
  listAdministrativeOffices: IListSelect[];
  listOffices: IListSelect[];

  constructor(
    private advisorService: AdvisorService,
    private cognitoService: CognitoService,
    private googleAnalyticsEventsService: GoogleAnalyticsEventsService,
    private googleRecaptchaService: GoogleRecaptchaService,
    private organizationManagerServices: OrganizationManagerService,
    private router: Router,
    private tealiumUtagSerivce: TealiumUtagService,
    private toggleService: ToggleService,
    private trainingService: TrainingService,
  ) {}

  ngOnInit() {
    this.googleAnalyticsEventsService.emitEvent(
      GoogleAnalyticsEventsEnum.LOGIN_ADVISOR_INIT_PAGE,
    );

    this.description =
      "Necesitamos que te identifiques como asesor del banco para comenzar con el proceso de libranza";

    this.initForm();
    this.initInformationToken();
    this.initInformation();
    this.hiddenFreshChat();
  }

  ngAfterViewInit(): void {
    this.tealiumUtagSerivce.view({
      page_path: document.location.pathname,
    });
  }

  closeMessageBox() {
    if (
      this.retryLookToggleService < ApplicationConstantsEnum.NUMBER_ATTEMPTS
    ) {
      this.formPopupMessageBoxComponent.setShowMessageBox = false;
    } else {
      window.location.reload();
    }
  }

  focusAdvisorDocument() {
    this.showSearchAdvisorSuccess = false;
  }

  goToDecision() {
    if (this.formGroup.valid) {
      this.googleAnalyticsEventsService.emitEvent(
        GoogleAnalyticsEventsEnum.LOGIN_ADVISOR_CLICK_CONTINUE,
        true,
      );
      this.loadingButtonAdvisor = true;

      this.advisorService.setAdministrativeOffice(
        this.formGroup.controls.valueAdministrativeOffices.value !== null
          ? this.formGroup.controls.valueAdministrativeOffices.value
          : undefined,
      );
      this.advisorService.setOffice(this.formGroup.controls.valueOffices.value);

      this.cognitoService.getToken().subscribe((data) => {
        this.advisorService.setCognitoToken(data.accessToken);
      });

      this.advisorService.setCognitoTokenLegacy(
        this.cognitoService.getTokenLegacy(),
      );
      this.advisorService.setCognitoTokenNew(this.cognitoService.getTokenNew());

      this.tealiumUtagSerivce.link({
        tealium_event: "click",
        event_category: "login",
        event_label: "Continuar",
      });

      setTimeout(() => {
        this.tealiumUtagSerivce.managementUserId(
          this.tealiumUtagSerivce.generateUserId(
            this.advisorService.getAdvisor().sellerIdNumber,
          ),
        );
        this.router.navigate(["decision"]);
      }, 500);
    }
  }

  hiddenFreshChat() {
    const fcButtonElement = document.getElementById("custom_fc_button");
    const fcWidgetElement = document.getElementById("open_fc_widget");

    if (fcButtonElement) {
      fcButtonElement.style.visibility = "hidden";

      if (fcWidgetElement) {
        fcWidgetElement.style.visibility = "hidden";
        fcWidgetElement.replaceWith(fcWidgetElement.cloneNode(true));
      }
    }
  }

  search() {
    if (!this.loadingDocumentAdvisor) {
      this.googleAnalyticsEventsService.emitEvent(
        GoogleAnalyticsEventsEnum.LOGIN_ADVISOR_SEARCH,
        true,
      );

      this.loadingDocumentAdvisor = true;
      this.leaveFocusTextAdvisor = true;

      this.googleRecaptchaService
        .generateTokenRecaptcha()
        .subscribe((token: any) => {
          this.searchAdvisor(token);
        });
    }
  }

  private initForm() {
    this.formGroup = new UntypedFormGroup({
      textDocumentAdvisor: new UntypedFormControl("", Validators.required),
      textAdministrativeOffices: new UntypedFormControl(),
      valueAdministrativeOffices: new UntypedFormControl(),
      textOffices: new UntypedFormControl(),
      valueOffices: new UntypedFormControl(),
    });
  }

  private initInformation() {
    if (!this.toggleService.isChargedListToggle()) {
      if (
        this.retryLookToggleService < ApplicationConstantsEnum.NUMBER_ATTEMPTS
      ) {
        setTimeout(
          () => this.initInformation(),
          ApplicationConstantsEnum.TIME_RETRY_REQUEST_TOGGLE,
        );

        this.retryLookToggleService++;
      } else {
        this.openMessageBox(true, MessageEnum.ERROR_CRASHED_OVERALL_SERVICES);
      }
    } else {
      this.initOrganizationInformation();
    }
  }

  private initInformationToken() {
    this.cognitoService.currentCognitoToken.subscribe((token: any) => {
      this.loadingDocumentAdvisor = !token;
    });
  }

  private initOrganizationInformation() {
    this.sendCognitoTokenToLambdas();
  }

  private openMessageBox(showMessageBox: boolean, messageEnum: MessageEnum) {
    this.formPopupMessageBoxComponent.setShowMessageBox = showMessageBox;
    this.formPopupMessageBoxComponent.setMessageEnum = messageEnum;
  }

  private searchAdvisor(recaptchaToken: any) {
    this.advisorService
      .asyncSearchAdvisor({
        documentNumber: Number(
          this.formGroup.controls.textDocumentAdvisor.value,
        ),
        googleAnalyticsId: this.googleAnalyticsEventsService.getClientId(),
        recaptchaResponse: recaptchaToken,
      })
      .then((advisor) => {
        this.showSearchAdvisorSuccess = advisor !== undefined;
        this.formGroup.controls.textOffices.setValidators([
          Validators.required,
        ]);

        this.showAdministrativeOffice = false;
        if (
          advisor !== undefined &&
          advisor.advisorType === AdvisorTypeEnum.EXTERNAL_SALES_FORCE
        ) {
          this.showAdministrativeOffice = true;
          this.formGroup.controls.textAdministrativeOffices.setValidators([
            Validators.required,
          ]);
        }

        this.trainingService.searchAdvisorCourses(advisor.sellerIdNumber);
      })
      .catch(() => {
        this.googleAnalyticsEventsService.emitEvent(
          GoogleAnalyticsEventsEnum.LOGIN_ADVISOR_SEARCH_ERROR,
        );

        this.openMessageBox(true, MessageEnum.ERROR_SEARCH_ADVISOR);
        this.showSearchAdvisorSuccess = false;
      })
      .finally(() => {
        this.loadingDocumentAdvisor = false;
        this.leaveFocusTextAdvisor = false;
      });
  }

  private sendCognitoTokenLegacy() {
    this.cognitoService.getTokenLegacy();
  }

  private sendCognitoTokenToLambdas() {
    this.cognitoService
      .getToken()
      .pipe()
      .subscribe((data: ICognitoCredentials) => {
        if (data) {
          this.cognitoToken = data.accessToken;
          this.setSelectAdministrativeOffices(this.cognitoToken);
          this.setSelectOffices(this.cognitoToken);
        }
      });
  }

  private setSelectAdministrativeOffices(cognitoToken: string = "") {
    this.organizationManagerServices
      .searchAdministrativeOfficesSelect(cognitoToken)
      .subscribe(
        (data) => {
          this.listAdministrativeOffices = data;
        },
        (error) => {
          this.listAdministrativeOffices = error;
        },
      );
  }

  private setSelectOffices(cognitoToken: string = "") {
    this.organizationManagerServices
      .searchOfficesSelect(cognitoToken)
      .subscribe(
        (data) => {
          this.listOffices = data;
        },
        (error) => {
          this.listOffices = error;
        },
      );
  }
}
